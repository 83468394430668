var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"0"}},[(_vm.dialog)?_c('v-dialog',{attrs:{"max-width":"30%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" Nuevo Servicio ")]),_c('v-card-text',[_c('v-select',{attrs:{"items":_vm.$store.state.pricing.begEndList,"item-text":"name","item-value":"id","label":"Categoria..."},model:{value:(_vm.servicio.id_begend),callback:function ($$v) {_vm.$set(_vm.servicio, "id_begend", $$v)},expression:"servicio.id_begend"}}),_c('v-text-field',{attrs:{"label":"Descripción"},model:{value:(_vm.servicio.namegroupservice),callback:function ($$v) {_vm.$set(_vm.servicio, "namegroupservice", $$v)},expression:"servicio.namegroupservice"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.guardarServicio()}}},[_vm._v("Guardar")]),_c('v-btn',{attrs:{"color":"red","dark":""},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},[_vm._v("Cancelar")])],1)],1)],1):_vm._e(),_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" SERVICIOS A REALIZAR: "),_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-subheader',{staticClass:"mt-0"},[_c('h4',[_vm._v("Desmarque los servicios que NO desea utilizar.")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('h4',{staticClass:"mb-2"},[_vm._v("ORIGEN")]),_vm._l((_vm.$store.state.pricing.listServices.filter(
            function (v) { return v.codebegend == 'OR'; }
          )),function(service){return _c('v-checkbox',{key:service.id,attrs:{"dense":""},on:{"change":function($event){return _vm.showConfirmationDialog(service)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(service.namegroupservice)+" ")]},proxy:true}],null,true),model:{value:(service.status),callback:function ($$v) {_vm.$set(service, "status", $$v)},expression:"service.status"}})})],2),_c('v-col',{attrs:{"cols":"3"}},[_c('h4',{staticClass:"mb-2"},[_vm._v("FLETE")]),_vm._l((_vm.$store.state.pricing.listServices.filter(
            function (v) { return v.codebegend == 'FL'; }
          )),function(service){return _c('v-checkbox',{key:service.id,attrs:{"dense":""},on:{"change":function($event){return _vm.showConfirmationDialog(service)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(service.namegroupservice)+" ")]},proxy:true}],null,true),model:{value:(service.status),callback:function ($$v) {_vm.$set(service, "status", $$v)},expression:"service.status"}})})],2),_c('v-col',{attrs:{"cols":"3"}},[_c('h4',{staticClass:"mb-2"},[_vm._v("DESTINO")]),_vm._l((_vm.$store.state.pricing.listServices.filter(
            function (v) { return v.codebegend == 'DE'; }
          )),function(service){return _c('v-checkbox',{key:service.id,attrs:{"dense":""},on:{"change":function($event){return _vm.showConfirmationDialog(service)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(service.namegroupservice)+" ")]},proxy:true}],null,true),model:{value:(service.status),callback:function ($$v) {_vm.$set(service, "status", $$v)},expression:"service.status"}})})],2),_c('v-col',{attrs:{"cols":"3"}},[_c('h4',{staticClass:"mb-2"},[_vm._v("OPCIONAL")]),_vm._l((_vm.$store.state.pricing.listServices.filter(
            function (v) { return v.codebegend == 'OP'; }
          )),function(service){return _c('v-checkbox',{key:service.id,attrs:{"dense":""},on:{"change":function($event){return _vm.showConfirmationDialog(service)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(service.namegroupservice)+" ")]},proxy:true}],null,true),model:{value:(service.status),callback:function ($$v) {_vm.$set(service, "status", $$v)},expression:"service.status"}})}),_c('v-text-field',{attrs:{"prefix":"$","error-messages":_vm.$store.state.pricing.errorValorMercancia,"label":"Valor de la mercancia","type":"number","step":"0.10","min":"0","placeholder":"Valor de la mercancia...","autocomplete":"off","dense":"","rules":[
            function (v) { return !!v || 'Dato Requerido'; },
            function (v) { return /^(?!0\d+|\d*e)\d*(?:\.\d+)?$/.test(v) ||
              'Debe ser un número real entero positivo'; } ]},on:{"input":function($event){return _vm.recargarCostos()},"blur":function($event){return _vm.recargarCostos()}},model:{value:(_vm.$store.state.pricing.datosPrincipales.amount),callback:function ($$v) {_vm.$set(_vm.$store.state.pricing.datosPrincipales, "amount", $$v)},expression:"$store.state.pricing.datosPrincipales.amount"}}),(
            (this.$store.state.pricing.listServices.filter(
              function (v) { return v.codegroupservices == 15; }
            ).length > 0
              ? this.$store.state.pricing.listServices.filter(
                  function (v) { return v.codegroupservices == 15; }
                )[0].status
              : false) ||
            (this.$store.state.pricing.listServices.filter(
              function (v) { return v.codegroupservices == 2; }
            ).length > 0
              ? this.$store.state.pricing.listServices.filter(
                  function (v) { return v.codegroupservices == 2; }
                )[0].status
              : false) ||
            (this.$store.state.pricing.listServices.filter(
              function (v) { return v.codegroupservices == 6; }
            ).length > 0
              ? this.$store.state.pricing.listServices.filter(
                  function (v) { return v.codegroupservices == 6; }
                )[0].status
              : false)
          )?_c('v-text-field',{attrs:{"label":"Descripción de carga","placeholder":"Descripción de carga","autocomplete":"off","dense":""},model:{value:(_vm.$store.state.pricing.datosPrincipales.descripcioncarga),callback:function ($$v) {_vm.$set(_vm.$store.state.pricing.datosPrincipales, "descripcioncarga", $$v)},expression:"$store.state.pricing.datosPrincipales.descripcioncarga"}}):_vm._e()],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }